import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  SimpleGrid,
  Flex
} from '@chakra-ui/react';
import banner from '../../img/BB-Banner.jpg';
import pic from '../../img/pic-1.jpg';

const MiniatureBEarings = () => {
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <Box
        height="600px"  
        backgroundImage={banner}  
        backgroundPosition="center"
        backgroundSize="cover"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.5)" 
        />
        <Box zIndex="1" color="white" textAlign="left" px="4">
          <Heading as="h1" fontSize="4xl" ml={15}>
            {t('header.phrase15')}
          </Heading>
          <Text fontSize="xl" mt="4" ml={15} width='70%'>
          {t('header.phrase16')}
          </Text>
        </Box>
      </Box>

      <Box p={{ base: 4, md: 10 }}>
  <Flex gap={{ base: '20px', md: '50px' }} flexDirection={{ base: 'column', md: 'row' }}>
    <SimpleGrid columns={{ base: 1, md: 1 }}>
      <Flex direction="column" alignItems="center">
        <Box>
          <Image
            src={pic}
            alt="Precision Bearings"
            boxSize={{ base: '200px', md: '400px' }}
            objectFit="contain"
          />
        </Box>
        <Box mt={4}>
          <Link to="/contact">
            <Button
              colorScheme="orange"
              size="lg"
              width={{ base: '150px', md: '200px' }}
            >
              {t('slider.buttons.contact')}
            </Button>
          </Link>
        </Box>
      </Flex>
    </SimpleGrid>

    <Flex direction="column" alignItems={{ base: 'center', md: 'start' }} width={{ base: '100%', md: '70%' }}>
      <Box mt={10}>
        <Heading size={{ base: 'md', md: 'lg' }} mb={5}>{t('header.design_attributes')}</Heading>
        <List spacing={3} styleType="disc" pl={15}>
          <ListItem>{t('miniature.design_attributes.attribute_1')}</ListItem>
          <ListItem>{t('miniature.design_attributes.attribute_2')}</ListItem>
          <ListItem>{t('miniature.design_attributes.attribute_3')}</ListItem>
          <ListItem>{t('miniature.design_attributes.attribute_4')}</ListItem>
          <ListItem>{t('miniature.design_attributes.attribute_5')}</ListItem>
          <ListItem>{t('miniature.design_attributes.attribute_6')}</ListItem>
          <ListItem>{t('miniature.design_attributes.attribute_7')}</ListItem>
          <ListItem>{t('miniature.design_attributes.attribute_8')}</ListItem>
        </List>
      </Box>

      <Box mt={10}>
        <Heading size={{ base: 'md', md: 'lg' }} mb={5}>{t('header.applications')}</Heading>
        <List spacing={3} styleType="disc">
          <ListItem>{t('miniature.applications.application_1')}</ListItem>
          <ListItem>{t('miniature.applications.application_2')}</ListItem>
          <ListItem>{t('miniature.applications.application_3')}</ListItem>
          <ListItem>{t('miniature.applications.application_4')}</ListItem>
          <ListItem>{t('miniature.applications.application_5')}</ListItem>
          <ListItem>{t('miniature.applications.application_6')}</ListItem>
          <ListItem>{t('miniature.applications.application_7')}</ListItem>
        </List>
      </Box>

      <Box mt={10} textAlign="center">
        <Button
          as="a"
          href="path_to_pdf.pdf"
          download
          colorScheme="orange"
          size="lg"
        >
          {t('header.download')}
        </Button>
      </Box>
    </Flex>
  </Flex>
</Box>

    </Box>
  );
};

export default MiniatureBEarings;
