import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './context/userContext';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Login from './components/login/Login';
import Register from './components/registration/Register';
import Home from './components/home/Home';
import Bearings from './components/bearings/Bearings';
import Products from './components/products/Products';
import HousedUnits from './components/housedUnits/HousedUnits';
import BallBearings from './components/ballBearings/BallBearings';
import MechanicalTransmission from './components/mechanicalTransmission/MechanicalTransmission';
import ContactPage from './components/contacts/Contacts';
import IndustrialServices from './components/industrialServices/IndustrialServices';
import CylindricalBearings from './components/cylindricalBearings/CylindricalBearings';
import DeepGrooveBearings from './components/deepGrooveBearings/DeepGrooveBearings';
import CorrosionResBearings from './components/crdgBearings/CorrosionResBearings';
import AngularBearings from './components/angularBearings/AngularBearings';
import PrecisionBearings from './components/precisionBearings/PrecisionBearings';
import About from './components/about/About';
import SingleRowCylindricalBearings from './components/singleRowCylBearings/singleRowCylBearings';
import ThrustBearings from './components/thrustBearings/thrustBearings';
import SphericalBearings from './components/sphericalBearings/sphericalBearings';
import TaperedBearings from './components/taperedBearings/taperedBearings';
import IntegratedAssysBearings from './components/integratedAssys/integratedAssys';
import AdaptBearings from './components/adaptBearings/adaptBearings';
import OilSeals from './components/oilSeals/oilSeals';
import Tools from './components/maintenanceTools/maintenanceTools';
import PlainBearings from './components/plainBearings/plainBearings';
import MiniatureBearings from './components/miniatureBBearings/miniatureBBearings';
import Precision from './components/precision/Precision';
import NURIURUBearings from './components/nu-riu-ru/nu-riu-ru';
import NRINRNBearings from './components/n-rin-rn/n-rin-rn';
import NJRIJRJBearings from './components/nj-rij-rj/nj-rij-rj';
import NFRIFRFBearings from './components/nf-rif-rf/nf-rif-rf';
import NPRITRTBearings from './components/np-rit-rt/np-rit-rt';
import NUPRIPRPBearings from './components/nup-rip-rp/nup-rip-rp';
import CRDBRBearings from './components/cylindricalDoubleRow/cylindricalDoubleRow';
import CRMRBearings from './components/cylindricalMultipleRow/cylindricalMultipleRow';
import SRFCBearings from './components/singleRowFC/singleRowFC';
import DRFCBearings from './components/doubleRowFC/doubleRowFC';
import TVLBearings from './components/tvlBearing/tvlBearing';
import DTVLBearings from './components/dtvlBearing/dtvlBearing';
import TPBearings from './components/tpBearing/tpBearing';
import TPSBearings from './components/tpsBearing/tpsBearing';
import TSRBearings from './components/tsrBearing/tsrBearing';
import TTHDBearings from './components/tthdBearing/tthdBearing';
import TTHDFLBearings from './components/tthdflBearing/tthdflBearing';
import TTHDSBearings from './components/tthdsBearing/tthdsBearing';
import OscilatingBearings from './components/oscilatingBearing/oscilatingBearing';
import TXRBearings from './components/txrBearing/txrBearing';
import TSBearings from './components/tsBearing/tsBearing';
import TSFBearings from './components/tsfBearing/tsfBearing';
import TSUBearings from './components/tsuBearing/tsuBearing';
import TSLBearings from './components/tslBearing/tslBearing';
import TSRBBearing from './components/tsrbBearing/tsrbBearing';
import FEBBearings from './components/febBearing/febBearing';
import MSRBearings from './components/msrBearing/msrBearing';
import TDIBearings from './components/tdiBearing/tdiBearing';
import TDOBearings from './components/tdoBearing/tdoBearing';
import TNABearings from './components/tnaBearing/tnaBearing';
import SRBearings from './components/srBearing/srBearing';
import IMBearings from './components/imBearing/imBearing';
import DMBearings from './components/dmBearing/dmBearing';
import TMBearings from './components/tmBearing/tmBearing';
import MDRBearings from './components/mdrBearing/mdrBearing';
import TQOBearings from './components/tqoBearing/tqoBearing';
import TQOWBearings from './components/tqowBearing/tqowBearing';
import TQITBearings from './components/tqitBearing/tqitBearing';
import SRNBBearings from './components/srnbBearing/srnbBearing';
import APBearings from './components/apBearing/apBearing';
import AP2Bearings from './components/ap2Bearing/ap2Bearing';
import ShaveBearings from './components/shaveBearings/shaveBearings';
import UltraWindBearings from './components/ultrawindBearing/ultrawindBearing';
import AerospaceBallBearings from './components/aerospaceBBearing/aerospaceBBearing';
import AerospaceCylindricalBearings from './components/aerospaceCBearings/aerospaceCBearings';
import PrecisionSpeclBearings from './components/precisionSpecBearing/precisionSpecBearing';
import PrecisionDeepBearings from './components/precisionDeepGrooveBearing/precisionDeepBearing';
import PrecisionAngularBearings from './components/precisionAngularBearing/precisionAngularBearing';
import PrecisionBallScrewBearings from './components/precisionBallScrewBearing/precisionBallScrewBearing';
import PrecisionCrossBearings from './components/precisionCrossBearing/precisionCrossBearing';
import PrecisionCRBearings from './components/precisionCRBearing/precisionCRBearing';
import PrecisionTaperBearings from './components/precisionTaperBearing/precisionTaperBearing';
import PrecisionThinBearings from './components/precisionThinBearing/precisionThinBearing';
import RadialSPBearings from './components/radialSPBearing/radialSPBearing';
import RadialSPLBearings from './components/radialSPLBearing/radialSPLBearing';
import RadialAngularBearings from './components/radialAngularBearing/radialAngularBearing';
import OilSealProduct from './components/oilSealProduct/oilSealProduct';
import VSealProduct from './components/vSealProduct/vSealProduct';
import Heaters from './components/heaters/heaters';
import HydroPullers from './components/hydroPullers/hydroPullers';
import MechPullers from './components/mechPullers/mechPullers';
import MechAccess from './components/mountingAccessories/mountingAccessories';
import CorrosionUnits from './components/corrosionUnits/corrosionUnits';
import CorrosionPolyUnits from './components/corrosionPolyUnits/corrosionPolyUnits';
import UCUnits from './components/ucUnits/ucUnits';
import SBUnits from './components/solidBlockUnits/solidBlockUnits';
import SNTUnits from './components/sntUnits/sntUnits';
import ETypeUnits from './components/eTypeUnits/eTypeUnits';
import SplitUnits from './components/splitUnits/splitUnits';
import SAFUnits from './components/safUnits/safUnits';
import FafnirUnits from './components/fafnirUnits/fafnirUnits';
import Augers from './components/augers/augers';
import Belts from './components/belts/belts';
import Couplings from './components/couplings/couplings';
import Lubrication from './components/lubrication/lubrication';
import Encoders from './components/encoders/encoders';

function App() {
  const location = useLocation();

  const hideHeaderFooter = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/admin';

  return (
    <div className="App">
      <ToastContainer />
      {!hideHeaderFooter && <Header />}
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<Login />} /> 
        <Route path="/register" element={<Register />} /> 
        <Route path="/bearings" element={<Bearings />} />
        <Route path="/products" element={<Products />} />
        <Route path="/housed-units" element={<HousedUnits />} />
        <Route path="/ball-bearings" element={<BallBearings />} />
        <Route path="/mechanical-power-transmission" element={<MechanicalTransmission />} />
        <Route path="/industrial-services" element={<IndustrialServices />} />
        <Route path="/cylindrical" element={<CylindricalBearings />} />
        <Route path="/miniature" element={<MiniatureBearings />} />
        <Route path="/deep-groove" element={<DeepGrooveBearings />} />
        <Route path="/corrosion-resistant-deep-groove-ball-bearings" element={<CorrosionResBearings />} />
        <Route path="/angular-contact" element={<AngularBearings />} />
        <Route path="/precision" element={<PrecisionBearings />} />
        <Route path="/single-row" element={<SingleRowCylindricalBearings />} />
        <Route path="/thrust-bearings" element={<ThrustBearings />} />
        <Route path="/plain" element={<PlainBearings />} />
        <Route path='/tapered-bearings' element={<TaperedBearings />} />
        <Route path='/integrated-assemblies' element={<IntegratedAssysBearings />} />
        <Route path='/adapt-bearings' element={<AdaptBearings />} />
        <Route path='/spherical-bearings' element={<SphericalBearings />} />
        <Route path='/seals' element={<OilSeals />} />
        <Route path='/tools' element={<Tools />} />
        <Route path='/precision-bearings' element={<Precision />} />
        <Route path='/nu-riu-ru' element={<NURIURUBearings />} />
        <Route path='/n-rin-rn' element={<NRINRNBearings />} />
        <Route path='/nj-rij-rj' element={<NJRIJRJBearings />} />
        <Route path='/nf-rif-rf' element={<NFRIFRFBearings />} />
        <Route path='/np-rit-rt' element={<NPRITRTBearings />} />
        <Route path='/nup-rip-rp' element={<NUPRIPRPBearings />} />
        <Route path='/double-row' element={<CRDBRBearings />} />
        <Route path='/multiple-row' element={<CRMRBearings />} />
        <Route path='/single-row-full-complement' element={<SRFCBearings />} />
        <Route path='/double-row-full-complement' element={<DRFCBearings />} />
        <Route path='/type-tvl-angular-contact-thrust-ball-bearing' element={<TVLBearings />} />
        <Route path='/type-dtvl-two-direction-angular-contact-thrust-ball-bearing' element={<DTVLBearings />} />
        <Route path='/type-tp-thrust-cylindrical-roller-bearing' element={<TPBearings />} />
        <Route path='/type-tps-self-aligning-thrust-cylindrical-roller-bearing' element={<TPSBearings />} />
        <Route path='/spherical-roller-thrust-bearings-tsr' element={<TSRBearings />} />
        <Route path='/type-tthd-thrust-tapered-roller-bearing' element={<TTHDBearings />} />
        <Route path='/type-tthdfl-v-flat-thrust-tapered-roller-bearing' element={<TTHDFLBearings />} />
        <Route path='/thrust-screw-down-tapered-roller-bearing' element={<TTHDSBearings />} />
        <Route path='/thrust-osciliating-tapered-roller-bearing' element={<OscilatingBearings />} />
        <Route path='/thrust-crossed-roller-bearing' element={<TXRBearings />} />
        <Route path='/ts' element={<TSBearings />} />
        <Route path='/tsf-flanged-cup' element={<TSFBearings />} />
        <Route path='/tsu-unit-bearing' element={<TSUBearings />} />
        <Route path='/tsl-with-duo-face-plus-seals' element={<TSLBearings />} />
        <Route path='/tsrb-snap-ring-cup' element={<TSRBBearing />} />
        <Route path='/fuel-efficient-bearings' element={<FEBBearings />} />
        <Route path='/metric-single-row' element={<MSRBearings />} />
        <Route path='/tdi-two-row-double-inner-races' element={<TDIBearings />} />
        <Route path='/tdo-two-row-double-outer-race' element={<TDOBearings />} />
        <Route path='/tna-two-row-non-adjustable' element={<TNABearings />} />
        <Route path='/sr-two-row-non-adjustable' element={<SRBearings />} />
        <Route path='/2ts-im-spacer-assembly-with-indirect-mounting' element={<IMBearings />} />
        <Route path='/2ts-dm-spacer-assembly-with-direct-mounting' element={<DMBearings />} />
        <Route path='/2ts-tm-spacer-assembly-with-tandem-mounting' element={<TMBearings />} />
        <Route path='/metric-double-row' element={<MDRBearings />} />
        <Route path='/tqo-straight-bore-four-row-assembly' element={<TQOBearings />} />
        <Route path='/tqow-straight-bore-with-cone-face-lubrication-slots' element={<TQOWBearings />} />
        <Route path='/tqit-tapered-bore-four-row-assembly' element={<TQITBearings />} />
        <Route path='/srnb-sealed-four-row-bearing' element={<SRNBBearings />} />
        <Route path='/ap-integrated-bearing-assemblies' element={<APBearings />} />
        <Route path='/ap-2-integrated-bearing-assemblies' element={<AP2Bearings />} />
        <Route path='/sheave-pac-bearing-assembly-for-oil-gas-drilling-rigs' element={<ShaveBearings />} />
        <Route path='/timken-ultrawind-tapered-roller-bearings' element={<UltraWindBearings />} />
        <Route path='/precision-ball' element={<AerospaceBallBearings />} />
        <Route path='/precision-cylindrical-roller' element={<AerospaceCylindricalBearings />} /> /////////
        <Route path='/precision-assemblies' element={<PrecisionSpeclBearings />} />
        <Route path='/precision-deep-groove' element={<PrecisionDeepBearings />} />
        <Route path='/precision-angular-contact-ball' element={<PrecisionAngularBearings />} />
        <Route path='/precision-ball-screw-support-bearing' element={<PrecisionBallScrewBearings />} />
        <Route path='/precision-cross-roller-xr-taper-rollers' element={<PrecisionCrossBearings />} />
        <Route path='/precision-cylindrical-roller-bearings-for-machine-tool' element={<PrecisionCRBearings />} />
        <Route path='/precision-tapered-roller' element={<PrecisionTaperBearings />} />
        <Route path='/precision-thin-section-ball' element={<PrecisionThinBearings />} />
        <Route path='/radial-spherical-plain-bearings' element={<RadialSPBearings />} />
        <Route path='/radial-spherical-plain-bearings-with-lip-seals' element={<RadialSPLBearings />} />
        <Route path='/angular-contact-thrust-spherical-plain-bearings' element={<RadialAngularBearings />} />
        <Route path='/oil-seals' element={<OilSealProduct />} />
        <Route path='/v-seals' element={<VSealProduct />} />
        <Route path='/low-frequency-induction-heaters' element={<Heaters />} />
        <Route path='/hydraulic-pullers' element={<HydroPullers />} />
        <Route path='/mechanical-pullers' element={<MechPullers />} />
        <Route path='/mounting-accessories' element={<MechAccess />} />
        <Route path='/corrosion-resistant-ball-bearing-housed-units' element={<CorrosionUnits />} />
        <Route path='/timken-corrosion-resistant-poly-round-housed-units' element={<CorrosionPolyUnits />} />
        <Route path='/uc-series' element={<UCUnits />} />
        <Route path='/solid-block-spherical-roller-bearing' element={<SBUnits />} />
        <Route path='/snt-plummer-blocks' element={<SNTUnits />} />
        <Route path='/type-e-tapered-roller-bearing-housed-unit' element={<ETypeUnits />} />
        <Route path='/split-block-cylindrical-roller-bearing' element={<SplitUnits />} />
        <Route path='/saf-pillow-blocks' element={<SAFUnits />} />
        <Route path='/timken-fafnir-ball-bearing-housed-units' element={<FafnirUnits />} />
        <Route path='/augers' element={<Augers />} />
        <Route path='/belts' element={<Belts />} />
        <Route path='/couplings' element={<Couplings />} />
        <Route path='/lubrication-systems' element={<Lubrication />} />
        <Route path='/encoders-sensors' element={<Encoders />} />

      </Routes>

      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

function MainApp() {
  return (
    <Router>
      <UserProvider>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </UserProvider>
    </Router>
  );
}

export default MainApp;
