import React, { useContext } from 'react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Text, Stack, Image, Flex } from '@chakra-ui/react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/userContext';

import slide1 from '../../img/slide1.jpg';
import slide2 from '../../img/slide2.webp';
import slide3 from '../../img/slide3.jpg';
import slide4 from '../../img/slide4.webp';
import slide5 from '../../img/slide5.jpg';
import slide6 from '../../img/slide6.jpg';
import slide7 from '../../img/slide7.webp';

const CustomSlider = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(UserContext);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const slides = [
    { img: slide1, title: t('slider.slide1.title'), description: t('slider.slide1.description') },
    { img: slide2, title: t('slider.slide2.title'), description: t('slider.slide2.description') },
    { img: slide3, title: t('slider.slide3.title'), description: t('slider.slide3.description') },
    { img: slide4, title: t('slider.slide4.title'), description: t('slider.slide4.description') },
    { img: slide5, title: t('slider.slide5.title'), description: t('slider.slide5.description') },
    { img: slide6, title: t('slider.slide6.title'), description: t('slider.slide6.description') },
    { img: slide7, title: t('slider.slide7.title'), description: t('slider.slide7.description') },
  ];

  const handleOrderClick = () => {
    if (isAuthenticated) {
      navigate('/products');
    } else {
      navigate('/login');
    }
  };

  return (
    <Box maxW="100%" mx="auto" position="relative" overflow="hidden" fontSize={['xs', 'sm', 'md']}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <Box key={index} position="relative" height="500px">
            <Image src={slide.img} alt={`Slide ${index + 1}`} width="100%" height="100%" objectFit="cover" />
            <Flex
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(0, 0, 0, 0.5)"
              justifyContent="center"
              alignItems="left"
              direction="column"
              color="white"
              textAlign="left"
              px='40px'
            >
              <Heading size="xl" mb={4}>{slide.title}</Heading>
              <Text mb={6} maxW="600px" fontSize={['xs', 'sm', 'md']}>{slide.description}</Text>
              <Stack direction="row" spacing={4} marginTop='15px'>
                <Link to="/contact">
                <Button 
                  colorScheme="orange" 
                  variant="solid"
                  bg="#f7931e"
                  color="white"
                  padding="10px 20px"
                  borderRadius="5px"
                  border='none'
                  _hover={{ opacity: '0.9' }} 
                  _active={{ bg: "#e0e0e0" }}
                  fontSize={['xs', 'sm', 'md']}>  
                    {t('slider.buttons.contact')}
                </Button>
                </Link>
                <Button 
                  colorScheme="gray" 
                  variant="outline"
                  bg="#555559"
                  color="white"
                  padding="10px 20px"
                  borderRadius="5px"
                  border='none'
                  _hover={{ bg: "#5a5a5a" }}
                  _active={{ bg: "#6a6a6a" }}
                  ml={4}
                  onClick={handleOrderClick}
                  fontSize={['xs', 'sm', 'md']}>
                    {t('slider.buttons.order')}
                </Button>
              </Stack>
            </Flex>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default CustomSlider;
