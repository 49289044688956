import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import banner from '../../img/TRB-Banner.jpg';
import pic from '../../img/pic-44.jpg';
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Flex,
  List,
  ListItem
} from '@chakra-ui/react';

const TQOBearings = () => {
  const { t, i18n } = useTranslation();

  return (
    <Box>
    <Box
      height="600px"  
      backgroundImage={banner}  
      backgroundPosition="center"
      backgroundSize="cover"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.5)" 
      />
      <Box zIndex="1" color="white" textAlign="left" px="4">
        <Heading as="h1" fontSize="4xl" ml={15}>
            TQO (Straight Bore Four-Row Assembly)
        </Heading>
      </Box>
    </Box>

    <Box p={{ base: 4, md: 10 }}>
  <Flex gap={{ base: '20px', md: '50px' }} flexDirection={{ base: 'column', md: 'row' }}>
    <SimpleGrid columns={1}>
      <Flex direction="column" alignItems="center">
        <Box>
          <Image
            src={pic}
            alt="Precision Bearings"
            boxSize={{ base: '200px', md: '400px' }}
            objectFit="contain"
            width="100%"
          />
        </Box>
        <Box mt={4}>
          <Link to="/contact">
            <Button
              colorScheme="orange"
              size="lg"
              width={{ base: '150px', md: '200px' }}
            >
              {t('slider.buttons.contact')}
            </Button>
          </Link>
        </Box>
      </Flex>
    </SimpleGrid>

    <Flex direction="column" alignItems={{ base: 'center', md: 'start' }} width={{ base: '100%', md: '70%' }}>
      <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>
        {t('tqo_bearing.description')}
      </Text>
      <Heading as="h2" size={{ base: 'md', md: 'lg' }} mb={5}>
        {t('tqo_bearing.size_range.title')}
      </Heading>
      <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>
        {t('tqo_bearing.size_range.ID')}
      </Text>
      <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>
        {t('tqo_bearing.size_range.OD')}
      </Text>

      <Box mb={10}>
        <Heading as="h2" size={{ base: 'md', md: 'lg' }} mb={5}>
          {t('tqo_bearing.design_attributes.title')}
        </Heading>
        <List spacing={3} styleType="disc" ml={5}>
          <ListItem>{t('tqo_bearing.design_attributes.attribute1')}</ListItem>
          <ListItem>{t('tqo_bearing.design_attributes.attribute2')}</ListItem>
          <ListItem>{t('tqo_bearing.design_attributes.attribute3')}</ListItem>
          <ListItem>{t('tqo_bearing.design_attributes.attribute4')}</ListItem>
        </List>
      </Box>

      <Box mb={10}>
        <Heading as="h2" size={{ base: 'md', md: 'lg' }} mb={5}>
          {t('tqo_bearing.applications.application')}
        </Heading>
        <List spacing={3} styleType="disc" ml={5}>
          <ListItem>{t('tqo_bearing.applications.application1')}</ListItem>
        </List>
      </Box>

      <Box textAlign="center" mt={6}>
        <Button
          as="a"
          href="path_to_pdf.pdf"
          download
          colorScheme="orange"
          size="lg"
        >
          {t('header.download')}
        </Button>
      </Box>
    </Flex>
  </Flex>
</Box>

  </Box>
  );
};

export default TQOBearings;
