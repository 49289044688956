import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import banner from '../../img/CRB-Banner.jpg';
import {
  Box,
  Image,
  Heading,
  Text,
  Stack,
  Card,
  CardBody,
  SimpleGrid,
  Flex
} from '@chakra-ui/react';

const SingleRowCylindricalBearings = () => {
    const { t, i18n } = useTranslation();
    const [bearings, setBearings] = useState([]);

  useEffect(() => {
    const fetchBearings = async () => {
      try {
        const response = await axios.get('https://iqbearings.uz/api/single-row-cylindrical-bearings');
        setBearings(response.data);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    fetchBearings();
  }, [i18n.language]);

    const getProductLink = (product) => {
        switch (product.name.toLowerCase()) {
          case 'nj, nu (series 22, 23, 2 and 3)':
            return '/nu-riu-ru';
          case 'n (rin, rn)':
            return '/n-rin-rn';
          case 'nj (rij, rj)':
            return '/nj-rij-rj';
          case 'nf (rif, rf)':
            return '/nf-rif-rf';
          case 'np (rit, rt)':
            return '/np-rit-rt';
          case 'nup (rip, rp)':
            return '/nup-rip-rp';
          default:
            return '/';
        }
      };

  return (
    <Box>
      <Box
        overflow='hidden'
        height="600px"
        backgroundImage={banner}
        backgroundPosition="center"
        backgroundSize="cover"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        position="relative"
        paddingLeft="40px"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.5)"
        />
        <Box zIndex="1" color="white" textAlign="left" px="4">
          <Heading as="h1" fontSize="4xl" textAlign='left' marginLeft='15px'>
            {t('header.phrase13')}
          </Heading>
          <Text fontSize="xl" mt="4" textAlign='left' marginLeft='15px' width='70%'>
            {t('header.phrase14')}
          </Text>
        </Box>
      </Box>

      <Box p={5}
        backgroundPosition="center"
        backgroundSize="cover"
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingLeft="40px"
        paddingRight='40px'
      >
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
          {bearings.map((product) => (
            <Link to={getProductLink(product)} style={{ textDecoration: 'none' }}>
            <Card
              key={product.id}
              direction={{ base: 'column', sm: 'row' }}
              overflow="hidden"
              variant="outline"
              shadow="md"
              border="1px solid #E2E8F0"
              borderRadius="lg"
              p={5}
              maxWidth="1200px"
            >
              <Flex direction='column' align="center" w='100%'>
                <Image
                  objectFit="cover"
                  boxSize="150px"
                  src={product.image_url}
                  alt={product.name}
                  borderRadius="lg"
                  border="1px solid #E2E8F0"
                />
                <Stack flex="1" ml={5}>
                  <CardBody textAlign="left" padding="15px">
                    <Heading size="md" mb={3} fontSize="16px" color='orange'>
                      {product.name}
                    </Heading>
                  </CardBody>
                </Stack>
              </Flex>
            </Card>
            </Link>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default SingleRowCylindricalBearings;
