import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  Box,
  Heading,
  Input,
  Button,
  Textarea,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';

const ContactPage = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+?[0-9]{7,15}$/;
    return phoneRegex.test(phone);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!validatePhone(formData.phone)) {
      newErrors.phone = 'Invalid phone number';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleBlur = () => {
    validateForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();

    if (isFormValid) {
      try {
        const webhookUrl = 'https://bnzuz.bitrix24.kz/rest/19/xkul30bpu0t493cb/';
        
        const searchResponse = await axios.get(`${webhookUrl}crm.contact.list.json`, {
          params: {
            filter: { 'EMAIL': formData.email },
            select: ['ID', 'NAME', 'LAST_NAME', 'PHONE', 'EMAIL']
          }
        });

        let contactId;

        if (searchResponse.data.result.length > 0) {

          const contact = searchResponse.data.result[0];
          contactId = contact.ID;
          console.log('Контакт найден:', contact);
        } else {

          const newContactData = {
            fields: {
              NAME: formData.name,
              EMAIL: [{ VALUE: formData.email, VALUE_TYPE: 'WORK' }],
              PHONE: [{ VALUE: formData.phone, VALUE_TYPE: 'WORK' }],
              OPENED: 'Y'
            }
          };

          const createContactResponse = await axios.post(`${webhookUrl}crm.contact.add.json`, newContactData);
          if (createContactResponse.data.result) {
            contactId = createContactResponse.data.result;
            console.log('Новый контакт создан с ID:', contactId);
          } else {
            console.error('Ошибка при создании контакта:', createContactResponse.data);
            alert('Ошибка при создании контакта. Попробуйте снова.');
            return;
          }
        }

        const dealData = {
          fields: {
            TITLE: `Запрос от ${formData.name}`,
            TYPE_ID: 'SALE',
            STAGE_ID: 'C11:NEW',
            CONTACT_ID: contactId,
            OPENED: 'Y',
            ASSIGNED_BY_ID: 157,
            CURRENCY_ID: 'UZS',
            OPPORTUNITY: 0,
            COMMENTS: formData.message,
            CATEGORY_ID: 11,
          }
        };

        const createDealResponse = await axios.post(`${webhookUrl}crm.deal.add.json`, dealData);

        if (createDealResponse.data.result) {
          alert('Спасибо за Ваше обращение!\nВаш запрос успешно отправлен и в скором времени будет обработан нашим сотрудником!');
          setFormData({ name: '', email: '', phone: '', message: '' });
        } else {
          console.error('Ошибка при создании сделки:', createDealResponse.data);
          alert('Ошибка при отправке обращения. Попробуйте снова.');
        }
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        alert('Ошибка при отправке обращения. Проверьте подключение к интернету.');
      }
    }
  };

  return (
    <Box p={10} maxW="100%" mx="auto" bg="gray.50">
      <Heading as="h1" size="xl" textAlign="center" mb={10}>
        {t('contacts.location')}
      </Heading>

      <Box display="flex" justifyContent="center" mb={6}>
        <Box
          borderRadius="md"
          overflow="hidden"
          boxShadow="lg"
          border="2px solid #f7931e"
          width={{ base: '100%', md: '80%' }}
        >
          <iframe
            title="Google Map"
            width="100%"
            height="400px"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.9959732582515!2d69.24978007656247!3d41.2871921022033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8be051d28705%3A0xdea35ddfdc7939d!2s%D0%9E%D0%9E%D0%9E%20%22BNZ%20Industrial%20Support%22%20-%20Leica%20Geosystems%20Uzbekistan!5e0!3m2!1sru!2s!4v1727678780021!5m2!1sru!2s"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
          />
        </Box>
      </Box>

      <Heading as="h2" size="lg" textAlign="center" mt={16} mb={8}>
        {t('contacts.contactus')}
      </Heading>

      <Box as="form" maxW="800px" mx="auto" onSubmit={handleSubmit}>
        <Grid gap={6}>
          <GridItem>
            <FormControl isRequired isInvalid={errors.name}>
              <FormLabel color="gray.600" fontWeight="bold">
                {t('contacts.name')}
              </FormLabel>
              <Input
                bg='white'
                placeholder={t('contacts.name')}
                size="lg"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                focusBorderColor="#f7931e"
                borderColor="gray.300"
                _hover={{ borderColor: '#f7931e' }}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel color="gray.600" fontWeight="bold">
                {t('contacts.email')}
              </FormLabel>
              <Input
                bg='white'
                placeholder={t('contacts.email')}
                size="lg"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                focusBorderColor="#f7931e"
                borderColor="gray.300"
                _hover={{ borderColor: '#f7931e' }}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isRequired isInvalid={errors.phone}>
              <FormLabel color="gray.600" fontWeight="bold">
                {t('contacts.phone')}
              </FormLabel>
              <Input
                bg='white'
                placeholder={t('contacts.phone')}
                size="lg"
                name="phone"
                type="text"
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                focusBorderColor="#f7931e"
                borderColor="gray.300"
                _hover={{ borderColor: '#f7931e' }}
              />
              <FormErrorMessage>{errors.phone}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Box mt={6}>
          <FormControl isRequired isInvalid={errors.message}>
            <FormLabel color="gray.600" fontWeight="bold">
              {t('contacts.message')}
            </FormLabel>
            <Textarea
              placeholder={t('contacts.message')}
              size="lg"
              name="message"
              value={formData.message}
              onChange={handleChange}
              onBlur={handleBlur}
              focusBorderColor="#f7931e"
              borderColor="gray.300"
              _hover={{ borderColor: '#f7931e' }}
              rows={6}
              bg='white'
            />
            <FormErrorMessage>{errors.message}</FormErrorMessage>
          </FormControl>
        </Box>

        <Button
          type="submit"
          colorScheme="orange"
          mt={8}
          size="lg"
          width="100%"
          bg="#f7931e"
          color="white"
          _hover={{ bg: "#e0821c" }}
          _active={{ bg: "#c97218" }}
          isDisabled={!isFormValid}
        >
          {t('contacts.submit')}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactPage;
