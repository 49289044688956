import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import banner from '../../img/TRB-Banner.jpg';
import {
    Box,
    Image,
    Heading,
    Text,
    Button,
    Stack,
    Card,
    CardBody,
    CardFooter,
    Flex,
    SimpleGrid
  } from '@chakra-ui/react';

const TaperedBearings = () => {
    const { t, i18n } = useTranslation();
    const [inchSingleRowBearings, setInchSingleRowBearings] = useState([]);
    const [metricSingleRowBearings, setMetricSingleRowBearings] = useState([]);
    const [inchDoubleRowBearings, setInchDoubleRowBearings] = useState([]);
    const [metricDoubleRowBearings, setMetricDoubleRowBearings] = useState([]);
    const [fourRowBearings, setFourRowBearings] = useState([]);

    useEffect(() => {

        const fetchinchSignleRowBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/taperedinchsingle', {
            params: { lang: i18n.language },
          });
          setInchSingleRowBearings(response.data);
        };
    
        const fetchMetricSignleRowBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/taperedmetricsingle', {
            params: { lang: i18n.language },
          });
          setMetricSingleRowBearings(response.data);
        };
    
        const fetchInchDoubleRowBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/taperedinchdouble', {
            params: { lang: i18n.language },
          });
          setInchDoubleRowBearings(response.data);
        };

        const fetchMetricDoubleRowBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/taperedmetricdouble', {
            params: { lang: i18n.language },
          });
          setMetricDoubleRowBearings(response.data);
        };

        const fetchFourRowBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/taperedfourrow', {
            params: { lang: i18n.language },
          });
          setFourRowBearings(response.data);
        };
    
        fetchinchSignleRowBearings();
        fetchMetricSignleRowBearings();
        fetchInchDoubleRowBearings();
        fetchMetricDoubleRowBearings();
        fetchFourRowBearings();
      }, [i18n.language]);
    
      const renderBearings = (products) => (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    {products.map((product) => (
                        <Card
                            key={product.id}
                            direction={{ base: 'column', sm: 'row' }}
                            overflow="hidden"
                            variant="outline"
                            shadow="md"
                            border="1px solid #E2E8F0"
                            borderRadius="lg"
                            p={5}
                            maxWidth="1200px"
                        >
                            <Flex direction={{ base: 'column', md: 'row' }} w="100%" align="center">
                                <Image
                                    objectFit="cover"
                                    boxSize="150px"
                                    src={product.image_url}
                                    alt={product.name}
                                    borderRadius="lg"
                                    border="1px solid #E2E8F0"
                                />
                                <Stack flex="1" ml={5}>
                                    <CardBody textAlign="left" padding="15px">
                                        <Heading size="md" mb={3} fontSize="16px">
                                            {product.name}
                                        </Heading>
                                        <Text fontSize="14px">{product.description}</Text>
                                    </CardBody>
                                    <CardFooter>
                                        <Link to={getProductLink(product)} style={{ textDecoration: 'none' }}>
                                            <Button
                                                variant="solid"
                                                bg="#f7931e"
                                                color="white"
                                                _hover={{ bg: "#e0821c" }}
                                                _active={{ bg: "#c97218" }}
                                                _focus={{ boxShadow: 'outline' }}
                                                px={6}
                                                py={3}
                                                border="none"
                                                borderRadius="4px"
                                                padding="10px"
                                                marginLeft="15px"
                                                cursor="pointer"
                                            >
                                                {t('products.details')}
                                            </Button>
                                        </Link>
                                    </CardFooter>
                                </Stack>
                            </Flex>
                        </Card>
                    ))}
                </SimpleGrid>
      );

      const getProductLink = (product) => {
        switch (product.name.toLowerCase()) {
            case 'ts':
            case 'ts конические однорядные':
                return '/ts';
            case 'tsf (flanged cup)':
            case 'tsf (с буртиком на наружном кольце)':
                return '/tsf-flanged-cup';
            case 'tsu (unit-bearing)':
            case 'tsu (unit-bearing)':
                return '/tsu-unit-bearing';
            case 'tsl (with duo face-plus seals)':
            case 'tsl (с уплотнениями duo face-plus)':
                return '/tsl-with-duo-face-plus-seals';
            case 'tsrb (snap-ring cup)':
            case 'tsrb (с канавкой для пружинного кольца)':
                return '/tsrb-snap-ring-cup';
            case 'fuel efficient bearings':
            case 'топливосберегающие подшипники':
                return '/fuel-efficient-bearings';
            case 'metric single row':
            case 'метрические однорядные подшипники':
                return '/metric-single-row';
            case 'tdi (two-row double inner race)':
            case 'tdi (двухрядный конический подшипник со сдвоенным внутренним кольцом)':
                return '/tdi-two-row-double-inner-races';
            case 'tdo (two-row double-outer race)':
            case 'tdo (двухрядный конический подшипник со двоенным наружным кольцом)':
                return '/tdo-two-row-double-outer-race';
            case 'tna (two-row non-adjustable)':
            case 'tna (нерегулируемый двухрядный конический подшипник)':
                return '/tna-two-row-non-adjustable';
            case 'sr (two-row non-adjustable)':
            case 'sr (двухрядный подшипник set-right)':
                return '/sr-two-row-non-adjustable';
            case '2ts-im (spacer assembly with indirect mounting)':
            case '2ts-im (с дистанционными кольцами и компоновкой о)':
                return '/2ts-im-spacer-assembly-with-indirect-mounting';
            case '2ts-dm (spacer assembly with direct mounting)':
            case '2ts-dm (с дистанционными кольцами и компоновкой х)':
                return '/2ts-dm-spacer-assembly-with-direct-mounting';
            case '2ts-tm (spacer assembly with tandem mounting)':
            case '2ts-tm (тандем с дистанционными кольцами)':
                return '/2ts-tm-spacer-assembly-with-tandem-mounting';
            case '2ts-tm (spacer assembly with tandem mounting)':
            case '2ts-tm (тандем с дистанционными кольцами)':
                return '/2ts-tm-spacer-assembly-with-tandem-mounting';
            case 'metric double row':
            case 'двухрядные метрические подшипники':
                return '/metric-double-row';
            case 'tqo (straight bore four-row assembly)':
            case 'tqo (четырехрядный конический подшипник с цилиндрическим отверстием)':
                return '/tqo-straight-bore-four-row-assembly';
            case 'tqow (straight bore with cone face lubrication slots)':
            case 'tqow (четырехрядный конический подшипник с цилиндрическим отверстием и пазами для смазки)':
                return '/tqow-straight-bore-with-cone-face-lubrication-slots';
            case 'tqit (tapered bore four-row assembly)':
            case 'tqit (четырехрядный конический подшипник с коническим отверстием)':
                return '/tqit-tapered-bore-four-row-assembly';
            case 'srnb (sealed four-row bearing)':
            case 'srnb (четырехрядный герметичный подшипник)':
                return '/srnb-sealed-four-row-bearing';
            default:
                return '/';
        }
    };

  return (
    <Box>
            <Box
                overflow='hidden'
                height="600px" 
                backgroundImage={`url(${banner})`}  
                backgroundPosition="center"
                backgroundSize="cover"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                paddingLeft="40px" 
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bg="rgba(0, 0, 0, 0.5)" 
                />

                <Box zIndex="1" color="white" textAlign="left" px="4">
                    <Heading as="h1" fontSize="4xl" textAlign='left' marginLeft='15px'>
                        {t('header.phrase32')}
                    </Heading>
                    <Text fontSize="xl" mt="4" textAlign='left' marginLeft='15px' width='80%'>
                        {t('header.phrase33')}
                    </Text>
                </Box>
            </Box>

            <Box p={5} 
              backgroundPosition="center"
              backgroundSize="cover"
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingLeft="40px"
              paddingRight='40px'
            >
                <SimpleGrid columns={{ base: 1, md: 1 }} spacing={6}>
                    
                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('taperedbearings.title1')}</Heading>
                        <Text mb={5} width='70%'>{t('taperedbearings.description1')}</Text>
                        {renderBearings(inchSingleRowBearings)}
                    </Box>

                    
                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('taperedbearings.title2')}</Heading>
                        <Text mb={5} width='70%'>{t('taperedbearings.description2')}</Text>
                        {renderBearings(metricSingleRowBearings)}
                    </Box>

                    
                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('taperedbearings.title3')}</Heading>
                        <Text mb={5} width='70%'>{t('taperedbearings.description3')}</Text>
                        {renderBearings(inchDoubleRowBearings)}
                    </Box>

                    
                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('taperedbearings.title4')}</Heading>
                        <Text mb={5} width='70%'>{t('taperedbearings.description4')}</Text>
                        {renderBearings(metricDoubleRowBearings)}
                    </Box>

                    
                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('taperedbearings.title5')}</Heading>
                        <Text mb={5} width='70%'>{t('taperedbearings.description5')}</Text>
                        {renderBearings(fourRowBearings)}
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
  );
};

export default TaperedBearings;
