import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import banner from '../../img/CRB-Banner.jpg';
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  Stack,
  Card,
  CardBody,
  CardFooter,
  SimpleGrid,
  Flex
} from '@chakra-ui/react';

const CylindricalBearings = () => {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://iqbearings.uz/api/cylindricalbearings', {
          params: { lang: i18n.language },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Ошибка при получении цилиндрических подшипников:', error);
      }
    };

    fetchProducts();
  }, [i18n.language]);

  const getProductLink = (product) => {
    switch (product.name.toLowerCase()) {
      case 'single row':
      case 'однорядные подшипники':
        return '/single-row';
      case 'double row':
      case 'двухрядные подшипники':
        return '/double-row';
      case 'multiple row':
      case 'многорядные подшипники':
        return '/multiple-row';
      case 'single row full complement':
      case 'однорядные полнотелые подшипники':
        return '/single-row-full-complement';
      case 'double row full complement':
      case 'двухрядные полнотелые подшипники':
        return '/double-row-full-complement';
      default:
        return '/products';
    }
  };

  return (
    <Box>
      <Box
        overflow='hidden'
        height="600px"
        backgroundImage={banner}
        backgroundPosition="center"
        backgroundSize="cover"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        position="relative"
        paddingLeft="40px"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.5)"
        />
        <Box zIndex="1" color="white" textAlign="left" px="4">
          <Heading as="h1" fontSize="4xl" textAlign='left' marginLeft='15px'>
            {t('header.phrase13')}
          </Heading>
          <Text fontSize="xl" mt="4" textAlign='left' marginLeft='15px' width='70%'>
            {t('header.phrase14')}
          </Text>
        </Box>
      </Box>

      <Box p={5}
        backgroundPosition="center"
        backgroundSize="cover"
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingLeft="40px"
        paddingRight='40px'
      >
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          {products.map((product) => (
            <Card
              key={product.id}
              direction={{ base: 'column', sm: 'row' }}
              overflow="hidden"
              variant="outline"
              shadow="md"
              border="1px solid #E2E8F0"
              borderRadius="lg"
              p={5}
              maxWidth="1200px"
            >
              <Flex direction={{ base: 'column', md: 'row' }} w="100%" align="center">
                <Image
                  objectFit="cover"
                  boxSize="150px"
                  src={product.image_url}
                  alt={product.name}
                  borderRadius="lg"
                  border="1px solid #E2E8F0"
                />
                <Stack flex="1" ml={5}>
                  <CardBody textAlign="left" padding="15px">
                    <Heading size="md" mb={3} fontSize="16px">
                      {product.name}
                    </Heading>
                    <Text fontSize="14px">
                      {product.description}
                    </Text>
                  </CardBody>
                  <CardFooter>
                    <Link to={getProductLink(product)} style={{ textDecoration: 'none' }}>
                      <Button
                        variant="solid"
                        bg="#f7931e"
                        color="white"
                        _hover={{ bg: "#e0821c" }}
                        _active={{ bg: "#c97218" }}
                        _focus={{ boxShadow: 'outline' }}
                        px={6}
                        py={3}
                        border="none"
                        borderRadius="4px"
                        padding="10px"
                        marginLeft="15px"
                        cursor="pointer"
                      >
                        {t('products.details')}
                      </Button>
                    </Link>
                  </CardFooter>
                </Stack>
              </Flex>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default CylindricalBearings;
