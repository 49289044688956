import React from 'react';
import CustomSlider from '../customSlider/Slider';

const Home = () => {
  return (
    <div>
      <CustomSlider />
    </div>
  )
}

export default Home