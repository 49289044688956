import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  VStack,
  Image,
  Spinner
} from '@chakra-ui/react';
import logo from '../../img/logo.png';

const Register = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return re.test(password);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const re = /^[0-9]{7,15}$/;
    return re.test(phoneNumber);
  };

  const validateName = (name) => {
    return name.length >= 2 && /^[A-Za-z]+$/.test(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateName(name)) {
      toast.error('Invalid name. Only letters are allowed and at least 2 characters.', { position: 'top-right' });
      return;
    }

    if (!validateName(surname)) {
      toast.error('Invalid surname. Only letters are allowed and at least 2 characters.', { position: 'top-right' });
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      toast.error('Invalid phone number. Only digits are allowed, between 7 and 15 characters.', { position: 'top-right' });
      return;
    }

    if (!validateEmail(email)) {
      toast.error('Invalid email format.', { position: 'top-right' });
      return;
    }

    if (!validatePassword(password)) {
      toast.error('Password must be at least 8 characters long, include one uppercase letter, one number, and one special character.', { position: 'top-right' });
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords don't match!", { position: 'top-right' });
      return;
    }

    try {
      const response = await axios.post('https://iqbearings.uz/api/auth/register', {
        email,
        password,
        name,
        surname,
        phone_number: phoneNumber,
      });

      if (response.status === 201) {
        toast.success('Registration successful!', { position: 'top-right' });
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        console.log('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data.error === 'User already exists') {
        toast.error('Email already exists!', { position: 'top-right' });
      } else {
        toast.error('Server error. Please try again later.', { position: 'top-right' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" bg="gray.50" p={6}>
      <Box
        bg="white"
        p={8}
        rounded="lg"
        shadow="lg"
        width="100%"
        maxW="500px"
        textAlign="center"
      >
        <VStack spacing={4} align="center">
          <Link to='/'>
            <Image src={logo} alt="Logo" width='auto' height='30px' />
          </Link>
          <Heading as="h2" size="lg" marginBottom='0px' marginTop='10px'>
            {t('header.signup')}
          </Heading>
        </VStack>

        <form onSubmit={handleSubmit}>
          <VStack spacing={4} mt={6}>
            <FormControl isRequired>
              <FormLabel>{t('header.name')}</FormLabel>
              <Input
                type="text"
                placeholder={t('header.name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                focusBorderColor="orange.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t('header.surname')}</FormLabel>
              <Input
                type="text"
                placeholder={t('header.surname')}
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                focusBorderColor="orange.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t('header.phone')}</FormLabel>
              <Input
                type="text"
                placeholder={t('header.phone')}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                focusBorderColor="orange.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t('header.email')}</FormLabel>
              <Input
                type="email"
                placeholder={t('header.email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                focusBorderColor="orange.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t('header.password')}</FormLabel>
              <Input
                type="password"
                placeholder={t('header.password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                focusBorderColor="orange.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t('header.confirmpassword')}</FormLabel>
              <Input
                type="password"
                placeholder={t('header.confirmpassword')}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                focusBorderColor="orange.400"
              />
            </FormControl>

            <Button type="submit" colorScheme="orange" width="100%" mt={4} isLoading={loading} loadingText="Signing in..." spinner={<Spinner color="white" />}>
              {t('header.signup')}
            </Button>
          </VStack>
        </form>

        <Text mt={6}>
          {t('header.yesaccount')}{' '}
          <Link to="/login" style={{ color: '#f7931e', textDecoration: 'underline' }}>
            {t('header.signin')}
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default Register;