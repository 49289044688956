import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import banner from '../../img/ENGB-Banner.jpg';
import {
    Box,
    Image,
    Heading,
    Text,
    Stack,
    Card,
    CardBody,
    Flex,
    SimpleGrid
  } from '@chakra-ui/react';

const Bearings = () => {
    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://iqbearings.uz/api/bearings', {
            params: { lang: i18n.language },
        });
        console.log(response.data);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [i18n.language]);

      const getProductLink = (product) => {
        switch (product.name.toLowerCase()) {
            case 'ball bearings':
            case 'шариковые подшипники':
                return '/ball-bearings'; 
            case 'cylindrical roller bearings':
            case 'цилиндрические роликовые подшипники':
                return '/cylindrical'; 
            case 'thrust bearings':
            case 'упорные подшипники':
                return '/thrust-bearings'; 
            case 'spherical roller bearings':
            case 'сферические роликовые подшипники':
                return '/spherical-bearings'; 
            case 'tapered roller bearings':
            case 'конические роликовые подшипники':
                return '/tapered-bearings'; 
            case 'integrated bearing assemblies':
            case 'интегрированные подшипниковые узлы':
                return '/integrated-assemblies'; 
            case 'adapt bearings':
            case 'подшипники серии adapt':
                return '/adapt-bearings'; 
            case 'precision bearings':
            case 'прецизионные подшипники':
                return '/precision'; 
            case 'spherical plain bearings':
            case 'сферические подшипники скольжения':
                return '/plain'; 
            case 'housed units':
            case 'корпусные подшипники':
                return '/housed-units'; 
            case 'seals':
            case 'уплотнители':
                return '/seals'; 
            case 'maintenance tools':
            case 'инструменты для ремонта':
                return '/tools'; 
            default:
                return '/'; 
        }
    };

  return (
    <Box>
            <Box
                overflow='hidden'
                height="600px"  
                backgroundImage={`url(${banner})`} 
                backgroundPosition="center"
                backgroundSize="cover"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                paddingLeft="40px" 
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bg="rgba(0, 0, 0, 0.5)" 
                />

                <Box zIndex="1" color="white" textAlign="left" px="4">
                    <Heading as="h1" fontSize="4xl" textAlign='left' marginLeft='15px'>
                        {t('header.engineeredBearings')}
                    </Heading>
                    <Text fontSize="xl" mt="4" textAlign='left' marginLeft='15px' width='80%'>
                        {t('header.phrase4')}
                    </Text>
                </Box>
            </Box>

            <Box p={5} 
              backgroundPosition="center"
              backgroundSize="cover"
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingLeft="40px"
              paddingRight='40px'
            >
                <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6}>
                    {products.map((product) => (
                        <Link to={getProductLink(product)} style={{ textDecoration: 'none' }}>
                        <Card
                            key={product.id}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            minHeight="300px"
                            maxHeight="300px"
                            overflow="hidden"
                            variant="outline"
                            shadow="md"
                            border="1px solid #E2E8F0" 
                            borderRadius="lg"
                            p={5}
                            maxWidth="1200px"
                        >
                            <Flex direction='column' align="center">
                                <Image
                                    objectFit="cover"
                                    boxSize='200px'
                                    src={product.image_url}
                                    alt={product.product_name}
                                    borderRadius="lg"
                                    border="1px solid #E2E8F0"  
                                    mb={{ base: 4, md: 0 }}  
                                    mr={{ md: 5 }}  
                                />

                                <Stack flex="1" textAlign="left">
                                    <CardBody padding='15px'>
                                        <Heading size="md" mb={3} fontSize='14px' color='orange' noOfLines={2}>
                                            {product.name}
                                        </Heading>
                                    </CardBody>
                                </Stack>
                            </Flex>
                        </Card>
                        </Link>
                    ))}
                </SimpleGrid>
            </Box>
        </Box>
  );
};

export default Bearings;
