import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import pic from '../../img/logo-1.jpg';

const About = () => {
    const { t, i18n } = useTranslation();

  return (
    <Box py={10} px={6} maxW="7xl" mx="auto" fontSize={['xs', 'sm', 'md']}>
      <Heading
        as="h3"
        size="2xl"
        textAlign="center"
        mb={8}
        color={useColorModeValue('gray.800', 'white')}
      >
        {t('about.aboutus')}
      </Heading>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
        align="center"
      >
        <Box flex="1" p={5}>
          <Heading as="h2" size="xl" mb={4}>
            {t('about.whoweare')}
          </Heading>
          <Text fontSize="lg" mb={6}>
            {t('about.text')}
          </Text>

          <Link to="/contact">
            <Button
              colorScheme="orange"
              bg="#f7931e"
              color="white"
              padding="10px 20px"
              borderRadius="5px"
              fontSize="1rem"
              _hover={{ opacity: '0.9' }}
            >
              {t('about.contact')}
            </Button>
          </Link>
        </Box>

        <Image
          src={pic}
          alt="About Us"
          flex="1"
          boxSize={{ base: '100%', md: '100px' }}
          ml={{ base: 0, md: 10 }}
          // mt={{ base: 10, md: 0 }}
        />
      </Flex>

      <Stack
        spacing={8}
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="space-between"
      >
        <Box flex="1" p={5}>
          <Heading as="h3" size="lg" mb={4}>
            {t('about.ourvalue')}
          </Heading>
          <Text fontSize="lg">
            {t('about.value')}
          </Text>
        </Box>

        <Box flex="1" p={5}>
          <Heading as="h3" size="lg" mb={4}>
            {t('about.ourmission')}
          </Heading>
          <Text fontSize="lg">
            {t('about.mission')}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default About;
