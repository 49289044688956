// theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Roboto, sans-serif", // Для заголовков
    body: "Roboto, sans-serif",    // Для основного текста
  },
  components: {
    Heading: {
      baseStyle: {
        fontSize: ['2xl', '3xl', '4xl'], // Responsive font sizes для Heading
      },
    },
    Text: {
      baseStyle: {
        fontSize: ['md', 'lg', 'xl'], // Responsive font sizes для Text
      },
    },
  },
});

export default theme;
