import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import banner from '../../img/PRECISION-Banner.jpg';
import pic from '../../img/pic-59.jpg';
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Flex,
  List,
  ListItem,
  Spinner
} from '@chakra-ui/react';

const PrecisionThinBearings = () => {
  const { t, i18n } = useTranslation();
  const [bearingData, setBearingData] = useState(null);
  const [loading, setLoading] = useState(true);

    const fetchBearingData = async () => {
      try {
        const response = await axios.get('https://iqbearings.uz/api/precisionthin', {
          params: { lang: i18n.language },
        });
        setBearingData(response.data[0]);
        setLoading(false);
      } catch (error) {
        console.error('Ошибка при получении данных о подшипниках:', error);
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchBearingData();
    }, [i18n.language]);
  
    if (loading) {
      return <Spinner size="xl" />;
    }
  
    if (!bearingData) {
      return <Text>{t('error.no_data')}</Text>;
    }

  return (
    <Box>
    <Box
      height="600px"  
      backgroundImage={banner}  
      backgroundPosition="center"
      backgroundSize="cover"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.5)"  
      />
      <Box zIndex="1" color="white" textAlign="left" px="4">
        <Heading as="h1" fontSize="4xl" ml={15}>
            {bearingData.name}
        </Heading>
      </Box>
    </Box>

    <Box p={{ base: 4, md: 10 }}>
  <Flex gap={{ base: '20px', md: '50px' }} flexDirection={{ base: 'column', md: 'row' }}>
    <SimpleGrid columns={1}>
      <Flex direction="column" alignItems="center">
        <Box>
          <Image
            src={pic}
            alt="Precision Bearings"
            boxSize={{ base: '200px', md: '400px' }}
            objectFit="contain"
            width="100%"
          />
        </Box>
        <Box mt={4}>
          <Link to="/contact">
            <Button
              colorScheme="orange"
              size="lg"
              width={{ base: '150px', md: '200px' }}
            >
              {t('slider.buttons.contact')}
            </Button>
          </Link>
        </Box>
      </Flex>
    </SimpleGrid>

    <Flex direction="column" alignItems={{ base: 'center', md: 'start' }} width={{ base: '100%', md: '70%' }}>
      <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>
        {t('precision_thin_section_bearings.description')}
      </Text>

      <Heading as="h2" size="lg" mb={5}>
        {t('precision_thin_section_bearings.title1')}
      </Heading>
      <List spacing={3} styleType="disc" ml={5}>
        <ListItem>{t('precision_thin_section_bearings.attributes.attribute1')}</ListItem>
        <ListItem>{t('precision_thin_section_bearings.attributes.attribute2')}</ListItem>
        <ListItem>{t('precision_thin_section_bearings.attributes.attribute3')}</ListItem>
        <ListItem>{t('precision_thin_section_bearings.attributes.attribute4')}</ListItem>
        <ListItem>{t('precision_thin_section_bearings.attributes.attribute5')}</ListItem>
      </List>

      <Box mb={10} mt={10}>
        <Heading as="h2" size="lg" mb={5}>
          {t('precision_thin_section_bearings.title2')}
        </Heading>
        <List spacing={3} styleType="disc" ml={5}>
          <ListItem>{t('precision_thin_section_bearings.applications.application1')}</ListItem>
          <ListItem>{t('precision_thin_section_bearings.applications.application2')}</ListItem>
          <ListItem>{t('precision_thin_section_bearings.applications.application3')}</ListItem>
          <ListItem>{t('precision_thin_section_bearings.applications.application4')}</ListItem>
          <ListItem>{t('precision_thin_section_bearings.applications.application5')}</ListItem>
          <ListItem>{t('precision_thin_section_bearings.applications.application6')}</ListItem>
        </List>
      </Box>

      <Box textAlign="center">
        <Button
          as="a"
          href="path_to_pdf.pdf"
          download
          colorScheme="orange"
          size="lg"
        >
          {t('header.download')}
        </Button>
      </Box>
    </Flex>
  </Flex>
</Box>

  </Box>
  );
};

export default PrecisionThinBearings;
