// src/Header.js
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Flex,
    Text,
    Button,
    VStack,
    Collapse,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image,
    useDisclosure,
    useColorModeValue,
    Stack
  } from '@chakra-ui/react';
  import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
  import { UserContext } from '../../context/userContext';
  import axios from 'axios';

const Header = () => {
    const { t, i18n } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { isOpen, onToggle } = useDisclosure();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const navigate = useNavigate();
    
    const { user, isAuthenticated, logoutUser, loginUser } = useContext(UserContext);

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
      };

    const toggleSubMenu = (menu) => {
        if (activeSubMenu === menu) {
          setActiveSubMenu(null); 
        } else {
          setActiveSubMenu(menu); 
        }
      };
  
      const handleLanguageChange = (language) => {
        i18n.changeLanguage(language); 
        setIsDropdownOpen(false);
      };

      const handleLogout = () => {
        logoutUser();
        localStorage.removeItem('token');  
        navigate('/');
    };

    useEffect(() => {
      const fetchUserData = async () => {
          const token = localStorage.getItem('token');
          if (token) {
              try {
                  const response = await axios.get('https://iqbearings.uz/api/auth/me', {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  });
                  loginUser(response.data); 
              } catch (error) {
                  console.error("Error fetching user data:", error);
                  logoutUser();
              }
          }
      };

      if (!user && isAuthenticated) {
          fetchUserData();
      }
  }, [isAuthenticated, user, loginUser, logoutUser]);

  return (
    <Box>
      <Flex bg="#555559" color="gray.600" justify="center" p={2} width="100%" zIndex="1001">
        <Text
          color="white"
          fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
        {t('header.info')}
      </Text>
    </Flex>

      <Flex
        bg={useColorModeValue('#f7931e', 'gray.800')}
        color="white"
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        align="center"
        justify="space-between"
        width='100%'
        zIndex='1000'
        top='34px'
      >

        <Flex flex={{ base: 1 }} justify="start">
          <Link to="/">
            <Image src={logo} alt="Logo" height="30px" />
          </Link>
        </Flex>

        <Flex display={{ base: 'none', md: 'flex' }} ml={10} flex={1} justify="center" mr={10}>
          <Stack direction="row" spacing={4} align="center">
            {/* <Menu>
              <MenuButton as={Button} variant="link" fontSize="sm" fontWeight={600} rightIcon={<ChevronDownIcon />} color="white">
                {t('header.brands')}
              </MenuButton>
              <MenuList>
                <MenuItem as={Link} to="/timken" color="#555559">TIMKEN®</MenuItem>
                <MenuItem as={Link} to="/beka" color="#555559">BEKA</MenuItem>
                <MenuItem as={Link} to="/cone-drive" color="#555559">Cone Drive</MenuItem>
                <MenuItem as={Link} to="/diamond-drives" color="#555559">Diamond Drives</MenuItem>
                <MenuItem as={Link} to="/groeneveld" color="#555559">Groeneveld</MenuItem>
                <MenuItem as={Link} to="/lovejoy" color="#555559">LoveJoy</MenuItem>
                <MenuItem as={Link} to="/philadelphia-gear" color="#555559">Philadelphia Gear</MenuItem>
                <MenuItem as={Link} to="/rollon" color="#555559">Rollon</MenuItem>
              </MenuList>
            </Menu> */}

            <Menu>
              <MenuButton as={Button} variant="link" fontSize="sm" fontWeight={600} rightIcon={<ChevronDownIcon />} color="white">
                {t('header.portfolio')}
              </MenuButton>
              <MenuList>
                <MenuItem as={Link} to="/products" color="#555559">{t('header.products')}</MenuItem>
                <MenuItem as={Link} to="/mechanical-power-transmission" color="#555559">{t('header.industrialMotion')}</MenuItem>
              </MenuList>
            </Menu>

            <Link to="/" style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>{t('header.home')}</Link>
            <Link to="/about" style={{ fontWeight: "600", color: "white", fontSize: "14px", width: "auto" }}>{t('header.about')}</Link>
            <Link to="/services" style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>{t('header.services')}</Link>
            <Link to="/contact" style={{ fontWeight: "600", color: "white", fontSize: "14px" }}>{t('header.contact')}</Link>
          </Stack>
        </Flex>

        <Flex alignItems="center" pr={5}>
            <Menu isOpen={isDropdownOpen} onClose={toggleDropdown}>
                <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FontAwesomeIcon icon={faGlobe} size="lg" />}
                    variant="outline"
                    onClick={toggleDropdown}
                />
                <MenuList>
                    <MenuItem onClick={() => handleLanguageChange('ru')} style={{ color: "#555559" }}>
                        <Flag code="RU" width='20px' className="flag-icon" style={{ marginRight: '8px' }} /> Русский
                    </MenuItem>
                    <MenuItem onClick={() => handleLanguageChange('en')} style={{ color: "#555559" }}>
                        <Flag code="US" width='20px' className="flag-icon" style={{ marginRight: '8px' }} /> English
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>

        <Flex display={{ base: 'none', md: 'flex' }} alignItems='center'>
            {isAuthenticated ? (
              <>
                <Text mr={4}>
                  {t('header.welcome')} {user?.name}
                </Text>
                <Button onClick={handleLogout} fontSize="sm" fontWeight={400} variant="outline">
                  {t('header.signout')}
                </Button>
              </>
            ) : (
              <>
                <Button as={Link} to="/login" fontSize="sm" fontWeight={400} variant="outline" mr={2}>
                  {t('header.signin')}
                </Button>
                <Button
                  as={Link}
                  to="/register"
                  display={{ base: 'none', md: 'inline-flex' }}
                  fontSize="sm"
                  fontWeight={600}
                  color="white"
                  bg="#555559"
                >
                  {t('header.signup')}
                </Button>
              </>
            )}
          </Flex>

        <Flex display={{ base: 'flex', md: 'none' }} onClick={onToggle}>
          <IconButton
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <VStack
          bg={useColorModeValue('white', 'gray.800')}
          p={4}
          display={{ md: 'none' }}
          align="start"
          spacing={4}
        >

          <Box w="100%">
            <Flex direction="column">
              <Link to="/" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>{t('header.home')}</Link>
              <Link to="/about" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>{t('header.about')}</Link>
              <Link to="/services" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>{t('header.services')}</Link>
              <Link to="/contact" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>{t('header.contact')}</Link>
            </Flex>
          </Box>

          {/* <Box w="100%">
            <Button
              onClick={() => toggleSubMenu('brands')}
              rightIcon={<ChevronDownIcon />}
              variant="link"
              fontSize="sm"
              fontWeight={600}
              w="100%"
              justifyContent="space-between"
            >
              {t('header.brands')}
            </Button>
            {activeSubMenu === 'brands' && (
              <VStack align="start" pl={4}>
                <Link to="/timken" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>TIMKEN®</Link>
                <Link to="/beka" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>BEKA</Link>
                <Link to="/cone-drive" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>Cone Drive</Link>
                <Link to="/diamond-drives" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>Diamond Drives</Link>
                <Link to="/groeneveld" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>Groeneveld</Link>
                <Link to="/lovejoy" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>LoveJoy</Link>
                <Link to="/philadelphia-gear" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>Philadelphia Gear</Link>
                <Link to="/rollon" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>Rollon</Link>
              </VStack>
            )}
          </Box> */}

          <Box w="100%">
            <Button
              onClick={() => toggleSubMenu('products')}
              rightIcon={<ChevronDownIcon />}
              variant="link"
              fontSize="sm"
              fontWeight={600}
              w="100%"
              justifyContent="space-between"
            >
              {t('header.portfolio')}
            </Button>
            {activeSubMenu === 'products' && (
              <VStack align="start" pl={4}>
                <Link to="/products" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>{t('header.products')}</Link>
                <Link to="/mechanical-power-transmission" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>{t('header.industrialMotion')}</Link>
              </VStack>
            )}
          </Box>

          {isAuthenticated ? (
            <Flex w="100%" justify="space-between">
              {/* <Text>{t('header.welcome')} {user?.name}</Text> */}
              <Link onClick={handleLogout} style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>
                {t('header.signout')}
              </Link>
            </Flex>
          ) : (
            <Flex w="100%" justify="space-between" direction="column">
              <Link to="/login" style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}>
                {t('header.signin')}
              </Link>
              <Link
                to="/register"
                fontSize="sm"
                fontWeight={600}
                variant="outline"
                color="white"
                bg="#555559"
                style={{ fontSize: "14px", fontWeight: "600", color: "#718096" }}
              >
                {t('header.signup')}
              </Link>
            </Flex>
          )}

        </VStack>
      </Collapse>
    </Box>
  );
}

export default Header;