import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading, Text, Icon, VStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box as="footer" bg="gray.800" color="white" py={10} px={6} fontSize={['xs', 'sm', 'md']}>
      <Flex justify="space-between" wrap="wrap" maxW="1200px" mx="auto" mb={10}>
        {/* <VStack align="start" spacing={3} mb={6} minW="200px">
          <Heading as="h3" size="md" mb={4}>
            {t('footer.brands')}
          </Heading>
          <Link to="/timken">{t('footer.timken')}</Link>
          <Link to="/beka">{t('footer.beka')}</Link>
          <Link to="/cone-drive">{t('footer.coneDrive')}</Link>
          <Link to="/diamond-drives">{t('footer.diamondDrives')}</Link>
          <Link to="/groeneveld">{t('footer.groeneveld')}</Link>
          <Link to="/lovejoy">{t('footer.lovejoy')}</Link>
          <Link to="/philadelphia-gear">{t('footer.philadelphiaGear')}</Link>
          <Link to="/rollon">{t('footer.rollon')}</Link>
        </VStack> */}

        <VStack align="start" spacing={3} mb={6} minW="200px">
          <Heading as="h3" size="md" mb={4}>
            {t('footer.engineeredBearings')}
          </Heading>
          <Link to="/ball-bearings">{t('footer.ball')}</Link>
          <Link to="/cylindrical">{t('footer.cylindrical')}</Link>
          <Link to="/housed-units">{t('footer.housedUnits')}</Link>
          <Link to="/plain">{t('footer.plain')}</Link>
          <Link to="/precision">{t('footer.precision')}</Link>
          <Link to="/spherical-bearings">{t('footer.spherical')}</Link>
          <Link to="/tapered-bearings">{t('footer.tapered')}</Link>
          <Link to="/thrust-bearings">{t('footer.thrust')}</Link>
        </VStack>

        <VStack align="start" spacing={3} mb={6} minW="200px">
          <Heading as="h3" size="md" mb={4}>
            {t('footer.industrialMotion')}
          </Heading>
          <Link to="/augers">{t('footer.augers')}</Link>
          <Link to="/lubrication-systems">{t('footer.lubricationSystems')}</Link>
          <Link to="/belts">{t('footer.belts')}</Link>
          <Link to="/chain">{t('footer.chain')}</Link>
          <Link to="/couplings">{t('footer.couplings')}</Link>
          <Link to="/encoders-sensors">{t('footer.encoder')}</Link>
        </VStack>

        <VStack align="start" spacing={3} mb={6} minW="200px">
          <Heading as="h3" size="md" mb={4}>
            {t('footer.contact')}
          </Heading>
          <Text>{t('footer.address')}:</Text>
          <Text>{t('footer.addressDetails')}</Text>
  
          <Text>{t('footer.phone')}:</Text>
          <Text>+998 90 062-77-67</Text>
  
          <Text>{t('footer.email')}:</Text>
          <Link href="mailto:contact@timken.com">
            psmirnov@bnzuz.com
          </Link>
  
          <Text>{t('footer.workingHours')}:</Text>
          <Text>{t('footer.workingHoursDetails')}</Text>
        </VStack>
      </Flex>

      <Flex justify="center" mb={6}>
        <Link to='https://youtube.com/@thetimkencompany?si=_JbzNJAoe6CoG7Ol'>
        <Icon as={FontAwesomeIcon} icon={faYoutube} boxSize={6} mx={3} _hover={{ color: 'orange.400' }} />
        </Link>
        <Link to='https://instagram.com/thetimkencompany'>
        <Icon as={FontAwesomeIcon} icon={faInstagram} boxSize={6} mx={3} _hover={{ color: 'orange.400' }} />
        </Link>
        <Link to='https://t.me/timkenuzb'>
        <Icon as={FontAwesomeIcon} icon={faTelegram} boxSize={6} mx={3} _hover={{ color: 'orange.400' }} />
        </Link>
      </Flex>

      <Box textAlign="center" color="gray.400">
        <Text mb={2}>© 2024 {t('footer.company')}</Text>
      </Box>
    </Box>
  );
}

export default Footer;
