import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import banner from '../../img/BB-Banner.jpg';
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  Stack,
  Card,
  CardBody,
  CardFooter,
  SimpleGrid,
  Grid,
  Flex
} from '@chakra-ui/react';

const IndustrialServices = () => {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://iqbearings.uz/api/products', {
          params: { lang: i18n.language },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Ошибка при получении продуктов:', error);
      }
    };

    fetchProducts();
  }, [i18n.language]);

    // Функция для создания ссылок на основе имени продукта
    const getProductLink = (product) => {
        switch (product.name.toLowerCase()) {
            case 'engineered bearing products':
            case 'промышленные подшипники':
                return '/bearings'; // Ссылка для подшипников
            case 'housed units (mounted bearings)':
            case 'корпусные узлы':
                return '/housed-units'; // Ссылка для корпусных узлов
            case 'mechanical power transmission':
            case 'системы передачи энергии':
                return '/power-transmission'; // Ссылка для систем передачи энергии
            case 'industrial services':
            case 'промышленные услуги':
                return '/industrial-services'; // Ссылка для промышленных услуг
            default:
                return '/products'; // По умолчанию ссылка на продукты
        }
    };

  return (
    <Box>
            {/* Баннер */}
            <Box
                overflow='hidden'
                height="400px"  // Высота баннера
                backgroundImage='https://www.timken.com/wp-content/uploads/2016/07/services-2-1.jpg'  // Указание на баннер
                backgroundPosition="center"
                backgroundSize="cover"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"  // Выравнивание содержимого по левому краю
                position="relative"
                paddingLeft="40px"  // Отступ слева для текста
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bg="rgba(0, 0, 0, 0.5)"  // Полупрозрачный черный фон для текста
                />
                <Box zIndex="1" color="white" textAlign="left" px="4"> {/* Текст выравниваем по левому краю */}
                    <Heading as="h1" fontSize="4xl" textAlign='left' marginLeft='15px'>
                        {t('header.phrase11')}
                    </Heading>
                    <Text fontSize="xl" mt="4" textAlign='left' marginLeft='15px' width='70%'>
                        {t('header.phrase12')}
                    </Text>
                </Box>
            </Box>

            {/* Основное содержимое страницы */}
            <Box p={5}
                backgroundPosition="center"
                backgroundSize="cover"
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingLeft="40px"
                paddingRight='40px'>
                {/* Карточки продуктов с использованием SimpleGrid */}
                
            </Box>
        </Box>
  );
};

export default IndustrialServices;
