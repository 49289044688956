import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import banner from '../../img/CRDG-Banner.jpg';
import pic from '../../img/pic-3.jpg';
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Flex,
  List,
  ListItem
} from '@chakra-ui/react';

const CorrosionResBearings = () => {
  const { t, i18n } = useTranslation();

  return (
    <Box>
    <Box
      height="600px"  
      backgroundImage={banner}  
      backgroundPosition="center"
      backgroundSize="cover"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.5)"  
      />
      <Box zIndex="1" color="white" textAlign="left" px="4">
        <Heading as="h1" fontSize="4xl" ml={15} width='60%'>
          {t('header.phrase19')}
        </Heading>
        <Text fontSize="xl" mt="4" ml={15} width='70%'>
        {t('header.phrase20')}
        </Text>
      </Box>
    </Box>

    <Box p={{ base: 4, md: 10 }}>
  <Flex gap={{ base: '20px', md: '50px' }} flexDirection={{ base: 'column', md: 'row' }}>
    <SimpleGrid columns={{ base: 1, md: 1 }}>
      <Flex direction="column" alignItems="center">
        <Box>
          <Image
            src={pic}
            alt="Precision Bearings"
            boxSize={{ base: '200px', md: '400px' }}
            objectFit="contain"
            width="100%"
          />
        </Box>
        <Box mt={4}>
          <Link to="/contact">
            <Button
              colorScheme="orange"
              size="lg"
              width={{ base: '150px', md: '200px' }}
            >
              {t('slider.buttons.contact')}
            </Button>
          </Link>
        </Box>
      </Flex>
    </SimpleGrid>

    <Flex direction="column" alignItems={{ base: 'center', md: 'start' }} width={{ base: '100%', md: '70%' }}>
      <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>
        {t('crdgb.phrase46')}
      </Text>
      <Text fontSize={{ base: 'sm', md: 'md' }} mb={5}>
        {t('crdgb.phrase47')}
      </Text>
      <List spacing={3} styleType="disc" pl={5}>
        <ListItem>{t('crdgb.phrase48')}</ListItem>
        <ListItem>{t('crdgb.phrase49')}</ListItem>
        <ListItem>{t('crdgb.phrase50')}</ListItem>
      </List>

      <Box mb={10} mt={10}>
        <Heading as="h2" size={{ base: 'md', md: 'lg' }} mb={5}>
          {t('crdgb.phrase51')}
        </Heading>
        <List spacing={3} styleType="disc" pl={5}>
          <ListItem>{t('crdgb.phrase52')}</ListItem>
          <ListItem>{t('crdgb.phrase53')}</ListItem>
          <ListItem>{t('crdgb.phrase54')}</ListItem>
          <ListItem>{t('crdgb.phrase55')}</ListItem>
        </List>
      </Box>

      <Box mb={10}>
        <Heading as="h2" size={{ base: 'md', md: 'lg' }} mb={5}>
          {t('crdgb.phrase56')}
        </Heading>
        <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight="bold">
          {t('crdgb.phrase57')}
        </Text>
        <Text fontSize={{ base: 'xs', md: 'sm' }}>
          {t('crdgb.phrase58')}
        </Text>
        <br />
        <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight="bold">
          {t('crdgb.phrase59')}
        </Text>
        <Text fontSize={{ base: 'xs', md: 'sm' }}>
          {t('crdgb.phrase60')}
        </Text>
        <br />
        <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight="bold">
          {t('crdgb.phrase61')}
        </Text>
        <Text fontSize={{ base: 'xs', md: 'sm' }}>
          {t('crdgb.phrase62')}
        </Text>
        <br />
        <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight="bold">
          {t('crdgb.phrase63')}
        </Text>
        <Text fontSize={{ base: 'xs', md: 'sm' }}>
          {t('crdgb.phrase64')}
        </Text>
      </Box>

      <Box mb={10}>
        <Heading as="h2" size={{ base: 'md', md: 'lg' }} mb={5}>
          {t('crdgb.phrase65')}
        </Heading>
        <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>
          {t('crdgb.phrase66')}
        </Text>
        <List spacing={3} styleType="disc" pl={5}>
          <ListItem>{t('crdgb.phrase67')}</ListItem>
          <ListItem>{t('crdgb.phrase68')}</ListItem>
          <ListItem>{t('crdgb.phrase69')}</ListItem>
          <ListItem>{t('crdgb.phrase70')}</ListItem>
          <ListItem>{t('crdgb.phrase71')}</ListItem>
          <ListItem>{t('crdgb.phrase72')}</ListItem>
        </List>
      </Box>

      <Box mb={10}>
        <Heading as="h2" size={{ base: 'md', md: 'lg' }} mb={5}>
          {t('crdgb.phrase73')}
        </Heading>
        <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>
          {t('crdgb.phrase74')}<br />
          {t('crdgb.phrase75')}<br />
          {t('crdgb.phrase76')}<br />
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} mb={4} fontWeight="bold">
          {t('crdgb.phrase77')}<br />
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} mb={4} fontWeight="bold">
          {t('crdgb.phrase78')}<br />
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} mb={4} fontWeight="bold">
          {t('crdgb.phrase79')}<br />
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} mb={4} fontWeight="bold">
          {t('crdgb.phrase80')}<br />
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} mb={4} fontWeight="bold">
          {t('crdgb.phrase81')}<br />
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>
          {t('crdgb.phrase82')}<br />
        </Text>
      </Box>

      <Box textAlign="center">
        <Button
          as="a"
          href="path_to_pdf.pdf"
          download
          colorScheme="orange"
          size="lg"
        >
          {t('header.download')}
        </Button>
      </Box>
    </Flex>
  </Flex>
</Box>

  </Box>
  );
};

export default CorrosionResBearings;
