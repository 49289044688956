import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); 
  const [isAuthenticated, setIsAuthenticated] = useState(false); 

  useEffect(() => {
    const token = localStorage.getItem('token'); 

    if (token) {
      axios.get('https://iqbearings.uz/api/auth/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(response => {
        if (response.data) {
          setUser(response.data); 
          setIsAuthenticated(true); 
        }
      })
      .catch(error => {
        console.error('Ошибка при получении данных пользователя: ', error);
        setIsAuthenticated(false); 
      });
    }
  }, []); 

  const loginUser = (userData) => {
    setUser(userData); 
    setIsAuthenticated(true); 
  };

  const logoutUser = () => {
    localStorage.removeItem('token'); 
    setUser(null); 
    setIsAuthenticated(false); 
  };

  return (
    <UserContext.Provider value={{ user, isAuthenticated, loginUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};
