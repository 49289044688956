import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import banner from '../../img/IM-Banner.jpg';
import {
  Box,
  Image,
  Heading,
  Text,
  Stack,
  Card,
  CardBody,
  SimpleGrid,
  Flex
} from '@chakra-ui/react';

const MechanicalTransmission = () => {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://iqbearings.uz/api/industrialmotion', {
          params: { lang: i18n.language },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Ошибка при получении продуктов:', error);
      }
    };

    fetchProducts();
  }, [i18n.language]);

    const getProductLink = (product) => {
        switch (product.name.toLowerCase()) {
            case 'augers':
            case 'шнеки':
                return '/augers';
            case 'belts':
            case 'ремни':
                return '/belts';
            case 'couplings and universal joints':
            case 'муфты и универсальные шарниры':
                return '/couplings';
            case 'lubrication and lubrication systems':
            case 'смазка и системы смазки':
                return '/lubrication-systems';
            case 'chain':
            case 'цепи':
                return '/precision-chain';
            case 'encoders':
            case 'кодировщики и датчики':
                return '/encoders-sensors';
            default:
                return '/';
        }
    };

  return (
    <Box>
            {/* Баннер */}
            <Box
                overflow='hidden'
                height="600px"  // Высота баннера
                backgroundImage={`url(${banner})`}  // Указание на баннер
                backgroundPosition="center"
                backgroundSize="cover"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"  // Выравнивание содержимого по левому краю
                position="relative"
                paddingLeft="40px"  // Отступ слева для текста
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bg="rgba(0, 0, 0, 0.5)"  // Полупрозрачный черный фон для текста
                />
                <Box zIndex="1" color="white" textAlign="left" px="4"> {/* Текст выравниваем по левому краю */}
                    <Heading as="h1" fontSize={['2xl', '3xl', '4xl']} textAlign='left' marginLeft='15px'>
                        {t('header.phrase7')}
                    </Heading>
                    <Text fontSize='xl' mt="4" textAlign='left' marginLeft='15px' width={['100%', '75%', '50%']}>
                        {t('header.phrase8')}
                    </Text>
                </Box>
            </Box>

            {/* Основное содержимое страницы */}
            <Box p={5}
                backgroundPosition="center"
                backgroundSize="cover"
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingLeft="40px"
                paddingRight='40px'>
                {/* Карточки продуктов с использованием SimpleGrid */}
                <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6}>
                    {products.map((product) => (
                        <Link to={getProductLink(product)} style={{ textDecoration: 'none' }}>
                        <Card
                            key={product.id}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            minHeight="300px"
                            maxHeight="300px"
                            overflow="hidden"
                            variant="outline"
                            shadow="md"
                            border="1px solid #E2E8F0" 
                            borderRadius="lg"
                            p={5}
                            maxWidth="1200px"
                        >
                            <Flex direction='column' align="center">
                                <Image
                                    objectFit="cover"
                                    boxSize='200px'
                                    src={product.image_url}
                                    alt={product.product_name}
                                    borderRadius="lg"
                                    border="1px solid #E2E8F0"  
                                    mb={{ base: 4, md: 0 }}  
                                    mr={{ md: 5 }}  
                                />

                                <Stack flex="1" textAlign="left">
                                    <CardBody padding='15px'>
                                        <Heading size="md" mb={3} fontSize='14px' color='orange' noOfLines={2}>
                                            {product.name}
                                        </Heading>
                                    </CardBody>
                                </Stack>
                            </Flex>
                        </Card>
                        </Link>
                    ))}
                </SimpleGrid>
            </Box>
        </Box>
  );
};

export default MechanicalTransmission;
