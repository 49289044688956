import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import banner from '../../img/ThB-Banner.jpg';
import {
    Box,
    Image,
    Heading,
    Text,
    Button,
    Stack,
    Card,
    CardBody,
    CardFooter,
    Flex,
    SimpleGrid
  } from '@chakra-ui/react';

const ThrustBearings = () => {
    const { t, i18n } = useTranslation();
    const [ballBearings, setBallBearings] = useState([]);
    const [cylindricalBearings, setCylindricalBearings] = useState([]);
    const [sphericalBearings, setSphericalBearings] = useState([]);
    const [taperedBearings, setTaperedBearings] = useState([]);

    useEffect(() => {

        const fetchBallBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/thrustball', {
            params: { lang: i18n.language },
          });
          setBallBearings(response.data);
        };
    
        const fetchCylindricalBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/thrustcylindrical', {
            params: { lang: i18n.language },
          });
          setCylindricalBearings(response.data);
        };
    
        const fetchSphericalBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/thrustspherical', {
            params: { lang: i18n.language },
          });
          setSphericalBearings(response.data);
        };
    
        const fetchTaperedBearings = async () => {
          const response = await axios.get('https://iqbearings.uz/api/thrusttapered', {
            params: { lang: i18n.language },
          });
          setTaperedBearings(response.data);
        };
    
        fetchBallBearings();
        fetchCylindricalBearings();
        fetchSphericalBearings();
        fetchTaperedBearings();
      }, [i18n.language]);
    
      const renderBearings = (products) => (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    {products.map((product) => (
                        <Card
                            key={product.id}
                            direction={{ base: 'column', sm: 'row' }}
                            overflow="hidden"
                            variant="outline"
                            shadow="md"
                            border="1px solid #E2E8F0"
                            borderRadius="lg"
                            p={5}
                            maxWidth="1200px"
                        >
                            <Flex direction={{ base: 'column', md: 'row' }} w="100%" align="center">
                                <Image
                                    objectFit="cover"
                                    boxSize="150px"
                                    src={product.image_url}
                                    alt={product.name}
                                    borderRadius="lg"
                                    border="1px solid #E2E8F0"
                                />
                                <Stack flex="1" ml={5}>
                                    <CardBody textAlign="left" padding="15px">
                                        <Heading size="md" mb={3} fontSize="16px">
                                            {product.name}
                                        </Heading>
                                        <Text fontSize="14px">{product.description}</Text>
                                    </CardBody>
                                    <CardFooter>
                                        <Link to={getProductLink(product)} style={{ textDecoration: 'none' }}>
                                            <Button
                                                variant="solid"
                                                bg="#f7931e"
                                                color="white"
                                                _hover={{ bg: "#e0821c" }}
                                                _active={{ bg: "#c97218" }}
                                                _focus={{ boxShadow: 'outline' }}
                                                px={6}
                                                py={3}
                                                border="none"
                                                borderRadius="4px"
                                                padding="10px"
                                                marginLeft="15px"
                                                cursor="pointer"
                                            >
                                                {t('products.details')}
                                            </Button>
                                        </Link>
                                    </CardFooter>
                                </Stack>
                            </Flex>
                        </Card>
                    ))}
                </SimpleGrid>
      );

      const getProductLink = (product) => {
        switch (product.name.toLowerCase()) {
            case 'type tvl-angular contact thrust ball bearing':
            case 'тип tvl-радиально-упорный шариковый подшипник':
                return '/type-tvl-angular-contact-thrust-ball-bearing';
            case 'type dtvl-two direction angular contact thrust ball bearing':
            case 'тип dtvl-двухнаправленный радиально-упорный шариковый подшипник':
                return '/type-dtvl-two-direction-angular-contact-thrust-ball-bearing';
            case 'type tp-thrust cylindrical roller bearing':
            case 'тип tp-упорный цилиндрический роликовый подшипник':
                return '/type-tp-thrust-cylindrical-roller-bearing';
            case 'type tps-self-aligning thrust cylindrical roller bearing':
            case 'тип tps-самовыравнивающийся упорный цилиндрический роликовый подшипник':
                return '/type-tps-self-aligning-thrust-cylindrical-roller-bearing';
            case 'spherical roller thrust bearings - tsr':
            case 'сферические роликовые упорные подшипники - tsr':
                return '/spherical-roller-thrust-bearings-tsr';
            case 'type tthd-thrust tapered roller bearing':
            case 'тип tthd-упорный конический роликовый подшипник':
                return '/type-tthd-thrust-tapered-roller-bearing';
            case 'type tthdfl-v-flat thrust tapered roller bearing':
            case 'тип tthdfl-v-flat упорный конический роликовый подшипник':
                return '/type-tthdfl-v-flat-thrust-tapered-roller-bearing';
            case 'thrust screw down tapered roller bearing':
            case 'упорный винтовой конический роликовый подшипник':
                return '/thrust-screw-down-tapered-roller-bearing';
            case 'thrust osciliating tapered roller bearing':
            case 'упорный колеблющийся конический роликовый подшипник':
                return '/thrust-osciliating-tapered-roller-bearing';
            case 'thrust crossed roller bearing':
            case 'упорный крестовой роликовый подшипник':
                return '/thrust-crossed-roller-bearing';
            default:
                return '/';
        }
    };

  return (
    <Box>
            <Box
                overflow='hidden'
                height="600px" 
                backgroundImage={`url(${banner})`} 
                backgroundPosition="center"
                backgroundSize="cover"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                paddingLeft="40px"  
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bg="rgba(0, 0, 0, 0.5)" 
                />

                <Box zIndex="1" color="white" textAlign="left" px="4">
                    <Heading as="h1" fontSize="4xl" textAlign='left' marginLeft='15px'>
                        {t('header.phrase25')}
                    </Heading>
                    <Text fontSize="xl" mt="4" textAlign='left' marginLeft='15px' width='80%'>
                        {t('header.phrase26')}
                    </Text>
                </Box>
            </Box>

            <Box p={5} 
              backgroundPosition="center"
              backgroundSize="cover"
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingLeft="40px"
              paddingRight='40px'
            >
                <SimpleGrid columns={{ base: 1, md: 1 }} spacing={6}>

                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('thrustbearings.balltitle')}</Heading>
                        <Text mb={5}>{t('thrustbearings.balldescription')}</Text>
                        {renderBearings(ballBearings)}
                    </Box>

                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('thrustbearings.cylindricaltitle')}</Heading>
                        <Text mb={5}>{t('thrustbearings.cylindricaldescription')}</Text>
                        {renderBearings(cylindricalBearings)}
                    </Box>

                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('thrustbearings.sphericaltitle')}</Heading>
                        <Text mb={5}>{t('thrustbearings.sphericaldescription')}</Text>
                        {renderBearings(sphericalBearings)}
                    </Box>

                    <Box mb={10}>
                        <Heading as="h2" size="lg" mb={5}>{t('thrustbearings.taperedtitle')}</Heading>
                        <Text mb={5}>{t('thrustbearings.tapereddescription')}</Text>
                        {renderBearings(taperedBearings)}
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
  );
};

export default ThrustBearings;
