import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, FormControl, FormLabel, Input, Heading, Text, VStack, Image, Spinner } from '@chakra-ui/react';
import logo from '../../img/logo.png';
import { UserContext } from '../../context/userContext';

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { loginUser } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://iqbearings.uz/api/auth/login', {
        email,
        password,
      });

      if (response.status === 200) {
        const { token, userData } = response.data;

        localStorage.setItem('token', token);

        loginUser(userData);

        toast.success('Login successful!', { position: 'top-right' });

        setTimeout(() => {
          navigate('/products');
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400) {
        toast.error('Invalid email or password!', { position: 'top-right' });
      } else {
        toast.error('Server error. Please try again later.', { position: 'top-right' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" bg="gray.50" p={6}>
      <Box
        bg="white"
        p={8}
        rounded="lg"
        shadow="lg"
        width="100%"
        maxW="400px"
        textAlign="center"
      >
        <VStack spacing={4} align="center">
          <Link to="/">
            <Image src={logo} alt="Logo" width='auto' height='30px' />
          </Link>
          <Heading as="h2" size="lg" marginBottom='0px' marginTop='10px'>
            {t('header.signin')}
          </Heading>
        </VStack>

        <form onSubmit={handleSubmit}>
          <VStack spacing={4} mt={6}>
            <FormControl isRequired>
              <FormLabel>{t('header.email')}</FormLabel>
              <Input
                type="email"
                placeholder={t('header.email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                focusBorderColor="orange.400"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t('header.password')}</FormLabel>
              <Input
                type="password"
                placeholder={t('header.password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                focusBorderColor="orange.400"
              />
            </FormControl>

            <Button
              type="submit"
              width="100%"
              colorScheme="orange"
              isLoading={loading}
              loadingText="Signing in..."
              spinner={<Spinner color="white" />}
            >
              {t('header.signin')}
            </Button>
          </VStack>
        </form>

        <Text mt={6}>
          {t('header.noaccount')}{' '}
          <Link to="/register" style={{ color: '#f7931e', textDecoration: 'underline' }}>
            {t('header.signup')}
          </Link>
        </Text>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Login;