import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import banner from '../../img/AUG-Banner.jpg';
import pic from '../../img/pic-77.jpg';
import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Flex
} from '@chakra-ui/react';

const Augers = () => {
  const { t, i18n } = useTranslation();

  return (
    <Box>
        <Box
            overflow='hidden'
            height="600px"  
            backgroundImage={`url(${banner})`} 
            backgroundPosition="center"
            backgroundSize="cover"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            paddingLeft="40px" 
        >
            <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                bg="rgba(0, 0, 0, 0.5)" 
            />

            <Box zIndex="1" color="white" textAlign="left" px="4">
                <Heading as="h1" fontSize="4xl" textAlign='left' marginLeft='15px'>
                    {t('augers.name')}
                </Heading>
                <Text fontSize="xl" mt="4" textAlign='left' marginLeft='15px' width='80%'>
                    {t('augers.description')}
                </Text>
            </Box>
        </Box>

        <Box p={10}>
  <Flex direction={{ base: 'column', md: 'row' }} gap="50px">
    <SimpleGrid columns={{ base: 1, md: 1 }}>
      <Flex direction="column" alignItems="center">
        <Box>
          <Image
            src={pic}
            alt="Precision Bearings"
            boxSize={{ base: "300px", md: "400px" }}
            objectFit="contain"
            width="100%"
          />
        </Box>
        <Box mt={4}>
          <Link to="/contact">
          <Button
              colorScheme="orange"
              size="lg"
              width={{ base: '150px', md: '200px' }}
            >
              {t('slider.buttons.contact')}
            </Button>
          </Link>
        </Box>
      </Flex>
    </SimpleGrid>

    <Flex direction="column" alignItems="center" width={{ base: '100%', md: '70%' }}>
      <Heading as="h2" size="lg" mb={5}>
        {t('augers_text.helicoid_augers.title')}
      </Heading>
      <Text fontSize={{ base: "14px", md: "16px" }} mb={4}>
        {t('augers_text.helicoid_augers.description')}
      </Text>
      
      <Heading as="h2" size="lg" mb={5}>
        {t('augers_text.sectional_augers.title')}
      </Heading>
      <Text fontSize={{ base: "14px", md: "16px" }} mb={4}>
        {t('augers_text.sectional_augers.description')}
      </Text>

      <Box textAlign="center" mt={6}>
        <Button
          as="a"
          href="path_to_pdf.pdf"
          download
          colorScheme="orange"
          size="lg"
        >
          {t('header.download')}
        </Button>
      </Box>
    </Flex>
  </Flex>
</Box>

  </Box>
  );
};

export default Augers;
